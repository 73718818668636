import ImageMap from "image-map";
import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import Loading from "../Loading";
import { useParams } from "react-router-dom";

const Webview = () => {
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [event, setEvent] = useState();
  const [loadCount, setLoadCount] = useState({
    total: 0,
    now: 0,
    finish: false,
  });

  const refs = useRef();

  // 이벤트 핸들러
  const eventHandler = (event) => {
    // setText(event.data);
    // const reactNative = JSON.parse(event.data);
    // getEventInfo(reactNative);
    // if (event.data) {
    //   if (reactNative && reactNative.id) {
    //   }
    // }
  };

  const getEventInfo = async (data) => {
    //   default
    let url = `https://api.myvenusnetwork.io/events/${data.id}`;

    // stage
    if (data.env === "stag") {
      url = `https://stag-api.myvenusnetwork.io/events/${data.id}`;
    }

    // local
    if (data.env === "local") {
      url = `http://localhost:6020/events/${data.id}`;
    }

    try {
      const response = await axios.get(url);

      if (response.status === 200) {
        setEvent(response.data);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  // 이벤트 추가
  const addEventListener = () => {
    // aos
    document.addEventListener("message", eventHandler);
    // ios
    window.addEventListener("message", eventHandler);
  };

  // 이벤트 제거
  const removeEventListener = () => {
    // aos
    document.removeEventListener("message", eventHandler);
    // ios
    window.removeEventListener("message", eventHandler);
  };

  const onPostMessage = (target, targetId) => {
    if (window.ReactNativeWebView) {
      const payload = JSON.stringify({
        id: event.id,
        target,
        targetId,
      });
      window.ReactNativeWebView.postMessage(payload);
    }
  };

  useEffect(() => {
    setLoading(true);
    addEventListener();
    if (params.id) {
      getEventInfo(params);
    }
    return () => {
      removeEventListener();
    };
  }, []);

  useEffect(() => {
    const { total, now, finish } = loadCount;
    // 총 개수와 현재 onload 개수가 같을 때
    if (total > 0 && now > 0 && total === now && !finish) {
      ImageMap("img[usemap]");
      // finish 는 한번만 요청하기 위해
      setLoadCount((prev) => ({ ...prev, finish: true }));
    }
  }, [loadCount, onPostMessage]);

  useEffect(() => {
    if (refs && refs.current) {
      if (refs && refs.current && refs.current.querySelector("map")) {
        refs.current.querySelectorAll("map").forEach((item) => {
          item.addEventListener("click", (e) => {
            e.preventDefault();
            const target = e.target;
            const targetData = target.getAttribute("href");
            const targetId = target.getAttribute("title");
            onPostMessage(targetData, targetId);
          });
        });
      }
      let count = 0;
      // 이미지 개수 체크
      refs.current.querySelectorAll("img").forEach((item) => {
        count += 1;
        item.onload = function () {
          setLoadCount((prev) => ({
            ...prev,
            now: prev.now + 1,
          }));
        };
      });
      setLoadCount((prev) => ({ ...prev, total: count }));
    }

    return () => {
      if (refs && refs.current) {
        refs.current.querySelectorAll("map").forEach((item) => {
          item.removeEventListener("click", () => {});
        });
      }
    };
  }, [event]);

  return (
    <div>
      {loading && <Loading />}
      {event && event.html_contents && (
        <div
          ref={refs}
          dangerouslySetInnerHTML={{ __html: event.html_contents }}
        ></div>
      )}
    </div>
  );
};

export default Webview;
