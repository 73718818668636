import React from "react";
import { useLottie } from "lottie-react";
import animationData from "./json/loading.json";

const Loading = () => {
  const options = {
    animationData,
    loop: true,
    autoplay: true,
  };

  const { View } = useLottie(options, {
    width: 100,
    height: 100,
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: `translate(-50%, -50%)`,
  });
  return View;
};

export default Loading;
