import React, { useEffect, useState } from "react";
import Logo from "../assets/myvenusLogoHorizontalBk1@2x.png";
import iconProfile from "../assets/iconProfile.png";
import imgInvitationCoupon from "../assets/imgInvitationCoupon.png";
import "./invite.css";
import ReactGA from "react-ga4";
import { useCallback } from "react";
import { isIOS, isAndroid, isMacOs, isWindows } from "react-device-detect";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

const Invite = () => {
  const location = useLocation();

  const qs = queryString.parse(location.search);
  const parts = location.pathname.split("/");
  const inviteCode = parts.length > 1 ? parts[2] : "";
  const inviteUserName = qs?.user_name;
  const inviteUserProfile_url = qs?.profile_url;
  const copyData = JSON.stringify({ myvenus: inviteCode });

  // GA
  const { REACT_APP_GA, NODE_ENV } = process.env;

  const [initialized, setInitialized] = useState(false);
  const [innerHeight, setInnerHeight] = useState(0);

  const redirect_to_web_store = useCallback((web_store_link) => {
    if (!document.hidden) {
      window.location.replace(web_store_link);
    }
  }, []);

  const goAppStore = useCallback(
    (e) => {
      if (NODE_ENV === "production") {
        ReactGA.event({
          category: "ios",
          action: "accept",
          label: `${inviteCode}_${inviteUserName}`,
        });
      }
    },
    [inviteCode, inviteUserName],
  );

  const goPlayStore = useCallback(
    (e) => {
      if (NODE_ENV === "production") {
        ReactGA.event({
          category: "android",
          action: "accept",
          label: `${inviteCode}_${inviteUserName}`,
        });
      }
    },
    [inviteCode, inviteUserName],
  );

  const onClickGoStore = useCallback(async () => {
    if (isIOS || isMacOs) {
      await window.navigator.clipboard.writeText(inviteCode);
      goAppStore();
      redirect_to_web_store(
        `https://apps.apple.com/id/app/myvenus-beauty-wellness/id1612742926?mt=8&myvenus=${inviteCode}`,
      );
      return;
    } else if (isAndroid || isWindows) {
      await window.navigator.clipboard.writeText(copyData);
      goPlayStore();
      redirect_to_web_store(
        `https://play.google.com/store/apps/details?id=io.myvenus&referrer=myvenus=${inviteCode}`,
      );
      return;
    } else {
      await window.navigator.clipboard.writeText(copyData);
      goPlayStore();
      redirect_to_web_store(
        `https://play.google.com/store/apps/details?id=io.myvenus&referrer=myvenus=${inviteCode}`,
      );
      return;
    }
  }, [copyData]);

  useEffect(() => {
    setInnerHeight(window.innerHeight);
    // GA
    if (NODE_ENV === "production") {
      ReactGA.initialize(REACT_APP_GA);
      setInitialized(true);
    }
  }, []);

  useEffect(() => {
    // GA
    if (NODE_ENV === "production" && initialized) {
      ReactGA.set({
        page: window.location.pathname,
      });
      ReactGA.send("pageview");
    }
  }, [initialized]);

  useEffect(() => {
    if (qs?.correct && qs?.correct === "n") {
      alert("Mohon maaf, kode undangan tidak valid.");
      redirect_to_web_store("https://share.myvenusnetwork.io?redirect=n");
    }
  }, []);

  return (
    <div
      className="invite"
      style={{ minHeight: innerHeight > 0 ? `${innerHeight}px` : undefined }}
    >
      <div className="inner">
        <div className="header-box">
          <div className="profile-img">
            {inviteUserProfile_url ? (
              <img src={inviteUserProfile_url} alt="" />
            ) : (
              <img src={iconProfile} alt="" />
            )}
          </div>
          <div className="coupon-img">
            <img src={imgInvitationCoupon} alt="" />
          </div>
        </div>
        <div className="info-text-box">
          <p>{`${inviteUserName} mengirimi Anda kupon`}</p>
          <p>{`diskon untuk prosedur Anda`}</p>
        </div>
        <div className="btn-box">
          <button
            type="button"
            className="store-link-btn"
            onClick={onClickGoStore}
          >{`Dapatkan kupon dari ${inviteUserName}`}</button>
        </div>
        {(isIOS || isMacOs) && (
          <div className="copy-info-text-box">
            <p>Tekan tombol untuk menyalin kode undangan.</p>
            <p>Masukan kode undangan pada saat mendaftar.</p>
          </div>
        )}
        <div className="footer-logo">
          <img src={Logo} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Invite;
