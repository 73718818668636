import "./App.css";
import Webview from "./components/webview";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import Invite from "./components/Invite";
import Deeplink from "./components/Deeplink";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Home />} path="/" />
        <Route element={<Deeplink />} path="/surgery/:id" />
        <Route element={<Deeplink />} path="/event/:id" />
        <Route element={<Deeplink />} path="/journal/:id" />
        <Route element={<Deeplink />} path="/doctor/:id" />
        <Route element={<Deeplink />} path="/hospital/:id" />
        <Route element={<Deeplink />} path="/review/:id" />
        <Route element={<Deeplink />} path="/mysurgery/:id" />
        <Route element={<Deeplink />} path="/brand/:id" />
        <Route element={<Deeplink />} path="/wedding" />
        <Route element={<Deeplink />} path="/home" />
        <Route element={<Deeplink />} path="/wish" />
        <Route element={<Deeplink />} path="/mysurgery" />
        <Route element={<Deeplink />} path="/mypages" />
        <Route element={<Deeplink />} path="/mycoupon" />
        <Route element={<Deeplink />} path="/discount" />
        <Route element={<Deeplink />} path="/faq" />
        <Route element={<Deeplink />} path="/notice" />
        <Route element={<Deeplink />} path="/endyear" />
        <Route element={<Deeplink />} path="/newyear" />
        <Route element={<Invite />} path="/invite/:id" />
        <Route element={<Webview />} path="/:id" />
        <Route element={<Webview />} path="/:env/:id" />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
