import React, { useEffect, useState, useMemo } from "react";
import Logo from "../assets/myvenusLogoHorizontalBk1@2x.png";
import Phone from "../assets/mockupPhone@2x.png";
import AppStore from "../assets/btnAppStore@2x.png";
import GooglePlay from "../assets/btnGooglePlay@2x.png";
import "./home.css";
import ReactGA from "react-ga4";
import { useCallback } from "react";
import { isIOS, isMobile } from "react-device-detect";

const Deeplink = () => {
  let systemDialogShown = false;
  let systemDialogDismissed = false;
  let new_link = "";

  function redirect_to_app() {
    if (!document.hidden) {
      window.location.href = new_link;
    }
  }

  function redirect_to_web_store(web_store_link) {
    if (!document.hidden) {
      window.location.replace(web_store_link);
    }
  }

  function redirect_to_web_or_store(web_store_link) {
    if (!document.hidden && systemDialogDismissed) {
      setTimeout(redirect_to_web_store(web_store_link), 200);
    }
  }

  if (isIOS && !navigator.userAgent.includes("Instagram")) {
    window.addEventListener(
      "blur",
      function (evt) {
        if (systemDialogDismissed) {
          systemDialogDismissed = false;
        }
        systemDialogShown = true;
      },
      false,
    );

    window.addEventListener(
      "focus",
      function (evt) {
        if (systemDialogShown) {
          systemDialogDismissed = true;
        }
        setTimeout(function () {
          redirect_to_web_or_store(
            "https://apps.apple.com/id/app/myvenus-beauty-wellness/id1612742926",
          );
        }, 900);
      },
      { once: true },
    );

    document.addEventListener("visibilitychange", function () {
      if (window.history.length > 1) {
        window.history.go(-1); // go back to previous page
      }
    });
  }

  const valid_scheme = (url) => {
    const reg2 = /(:\/\/)/;
    if (reg2.test(url)) {
      new_link = url;
      return true;
    } else {
      return false;
    }
  };

  // GA
  const { REACT_APP_GA, NODE_ENV } = process.env;
  const [initialized, setInitialized] = useState(false);
  const [innerHeight, setInnerHeight] = useState(0);

  const goDeeplink = useCallback(() => {
    const scheme = "myvenus://";
    const parts = window.location.href.split("share.myvenusnetwork.io", 2);
    const menu = parts.length > 1 ? parts[1].replace("/", "") : "";
    const url = `${scheme}${menu}`;

    if (isIOS) {
      if (!navigator.userAgent.includes("Instagram")) {
        try {
          if (valid_scheme(url)) {
            redirect_to_app();
          }
        } catch (e) {
          redirect_to_web_or_store(
            "https://apps.apple.com/id/app/myvenus-beauty-wellness/id1612742926",
          );
        }
      }
    } else {
      window.location.href = `intent://${menu}?#Intent;scheme=myvenus;action=android.intent.action.VIEW;category=android.intent.category.BROWSABLE;package=io.myvenus;end`;
    }
  }, []);

  useEffect(() => {
    setInnerHeight(window.innerHeight);
    // GA
    if (NODE_ENV === "production") {
      ReactGA.initialize(REACT_APP_GA);
      setInitialized(true);
      if (isMobile) {
        goDeeplink();
      }
    }
  }, []);

  useEffect(() => {
    // GA
    if (NODE_ENV === "production" && initialized) {
      ReactGA.set({
        page: window.location.pathname,
      });
      ReactGA.send("pageview");
    }
  }, [initialized]);

  const goAppStore = useCallback((e) => {
    if (NODE_ENV === "production") {
      ReactGA.event({
        category: "download",
        action: "go_appstore",
        label: "ios",
      });
    }
  }, []);

  const goPlayStore = useCallback((e) => {
    if (NODE_ENV === "production") {
      ReactGA.event({
        category: "download",
        action: "go_playstore",
        label: "android",
      });
    }
  }, []);

  return (
    <div
      className="home"
      style={{ minHeight: innerHeight > 0 ? `${innerHeight}px` : undefined }}
    >
      <div className="inner">
        <h1>My Beauty Assistant</h1>
        <p>MyVenus No.1 Beauty App in Indonesia</p>
        <div className="phone-img">
          <img src={Phone} alt="" />
        </div>
        <div className="btn-area">
          <a
            href="https://play.google.com/store/apps/details?id=io.myvenus"
            onClick={goPlayStore}
          >
            <img src={GooglePlay} alt="" />
          </a>
          <a
            href="https://apps.apple.com/id/app/myvenus-beauty-wellness/id1612742926"
            onClick={goAppStore}
          >
            <img src={AppStore} alt="" />
          </a>
        </div>
        <div className="footer-logo">
          <img src={Logo} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Deeplink;
